.site-header{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 98px;
    background: $white;
    box-shadow: 0 0 23px rgba($black, 0.1);
    z-index: 9;
    @include breakpoint(sm) {
        bottom: auto;
        right: 0;
        width: 100%;
        height: 19.2vw;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 0 5.3vw;
    }
    &__title{
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(sm) {
            height: auto;
            display: inline-block;
            position: absolute;
            right: 5.3vw;
            transform: translate(0vw, 0);
            transition: all 0.5s;
        }
    }
    &__head{
        writing-mode: vertical-rl;
        text-orientation: mixed;
        font-size: 18px;
        color: $blackPearl;
        margin: auto;
        height: 100%;
        letter-spacing: 0.11em;
        @include breakpoint(sm) {
            writing-mode: inherit;
            font-size: 3.78vw;
        }
        span{
            font-family: $default-book;
            margin-top: 0.4em;
        }
    }
    &__redborder{
        width: 13px;
        position: absolute;
        left: 100%;
        height: 31.25vh;
        background: $red;
        bottom: 0;
        transition: all 0.5s;
        @include breakpoint(sm) {
            height: 2.7vw;
            width: 40.7vw;
            left: 0;
            top: 100%;
        }
    }
    &__logo{
        position: absolute;
        top: 34px;
        width: 100%;
        text-align: center;
        transition: all 1s;
        @include breakpoint(sm) {
            position: relative;
            top: auto;
            width: auto;
            z-index: 2;
            img{
                width: 15.4vw;
            }
        }
    }
    &__menu_icon{
        position: absolute;
        width: 26px;
        text-align: center;
        top: 48px;
        line-height: normal;
        display: flex;
        flex-wrap: wrap;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        transition: all 1s;
        visibility: hidden;
        opacity: 0;
        @include breakpoint(sm) {
            height: 5vw;
            left: auto;
            right: 2vw;
            bottom: 0;
            top: 0;
            margin: auto;
            z-index: 2;
            width: 7vw;
        }
        span{
            width: 100%;
            height: 4px;
            background-color: $tuna;
            border-radius: 20px;
            display: inline-flex;
            margin: 2px 0;
            transition: all .5s;
            @include breakpoint(sm) {
                height: 1.07vw;
                margin: 0.5vw 0;
                border-radius: 50vw;
            }
        }
        &:hover{
            span{
                background-color: $red;
            }
        }
    }
    &__menu{
        position: fixed;
        height: 100vh;
        left: calc(-78.8vw + 111px);
        width: 78.8%;
        background-color: transparent;
        border-right: 13px solid transparent;
        top: 0;
        z-index: 2;
        padding: 80px 70px 40px;
        transition: all .7s;
        visibility: hidden;
        opacity: 0;
        @include breakpoint(sm) {
            left: calc(0vw + 0px);
            top: auto;
            bottom: calc(100vh - 20.93vw);
            width: 100%;
            border-right: 0;
            border-bottom: 2.7vw solid transparent;
            padding: 47vw 5.2vw 0vw;
        }
        .myProgress-block-outer{
            position: absolute;
            width: 13px;
            left: calc(100% + 1px);
            top: 0;
            height: 100vh;
            transition: all 0.5s;
            @include breakpoint(sm) {
                height: 2.7vw;
                width: 40.7vw;
                left: 0;
                top: 100%;
                display: none;
            }
            .myProgress-block{
                background: $red;
                position: absolute;
                top: 0;
                left: -1px;
                width: 100%;
            }
        }
        &::before{
            content: "";
            position: absolute;
            width: 13px;
            left: 100%;
            height: 31.25vh;
            background: $red;
            bottom: 69vh;
            transition: all 1s;
            opacity: 0;
            visibility: hidden;
            @include breakpoint(sm) {
                height: 2.7vw;
                width: 40.7vw;
                left: 0;
                top: 100%;
            }
        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $white;
        }
        .close-menu-icon{
            position: absolute;
            right: 42px;
            top: 42px;
            width: 66px;
            height: 66px;
            background-color: $tuna;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .5s;
            cursor: pointer;
            z-index: 2;
            @include breakpoint(sm) {
                right: 5.5vw;
                top: 26vw;
                width: 13.07vw;
                height: 13.07vw;
            }
            &:hover{
                background-color: $red;
            }
            img{
                @include breakpoint(sm) {
                    width: 3vw;
                    height: auto;
                }
            }
        }
        ul, .menu{
            padding: 0;
            width: 83%;
            counter-reset: my-counter;
            height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none;
            z-index: 2;
            position: relative;
            @include breakpoint(sm) {
                width: 100%;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            li{
                list-style: none;
                width: 100%;
                font-size: 3.85vh;
                color: $tuna;
                font-weight: 300;
                text-transform: uppercase;
                padding: 2.3vh 0;
                padding-right: 16.8vh;
                position: relative;
                counter-increment: my-counter;
                border-bottom: 1px solid rgba($tuna, 0.1);
                @include breakpoint(sm) {
                    font-size: 5.6vw;
                    padding: 4.5vw 0 5.9vw;
                }
                &::before{
                    content: "0" counter(my-counter);
                    color: $red;
                    font-weight: 400;
                    margin-right: 5%;
                    @include breakpoint(sm) {
                        margin-right: 5%;
                        font-weight: normal;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 4.6vh;
                    height: 1px;
                    background-color: $tuna;
                    right: 3.7vh;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all .5s;
                    @include breakpoint(sm) {
                        right: 0;
                    }
                }
                &:hover{
                    a{
                        color: $red;
                    }
                    &::after{
                        background-color: $red;
                    }
                }
                a{
                    display: inline-block;
                    line-height: 1.2em;
                }
            }
        }
    }
}

.left-header-space{
    padding-left: 98px;
    @include breakpoint(sm) {
        padding-left: 0px;
        padding-top: 0;
    }
}
.open-menu{
    .site-header{
        progress{
            @include breakpoint(sm){
                opacity: 0;
                visibility: hidden;
            }
        }
        &__menu{
            left: 98px;
            background: $white;
            @include breakpoint(sm) {
                left: 0;
                bottom: 0;
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
    .site-header__menu::before{
        height: 100vh;
        bottom: 0;
        opacity: 1;
        visibility: visible;
        @include breakpoint(sm) {
            width: 100vw;
            height: 2.7vw;
        }
    }
}
.show-scroll-section {
    .site-header__logo {
        top: 89vh;

        @include breakpoint(sm) {
            top: auto;
        }
    }

    .site-header__menu_icon {
        opacity: 1;
        visibility: visible;
    }

    .site-header__menu {
        opacity: 1;
        visibility: visible;

        @include breakpoint(sm) {
            opacity: 0;
            visibility: hidden;
        }
    }

    .open-menu {
        .site-head__menu {
            @include breakpoint(sm) {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }

    .site-header__redborder {
        opacity: 0;
        visibility: hidden;
        bottom: 100vh;
    }

    .site-header__title {
        @include breakpoint(sm) {
            transform: translate(50%, 0);
            right: 50%;
        }
    }
}
.second-step-active{
    .site-header__redborder{
        bottom: 69vh;
        @include breakpoint(sm) {
            bottom: auto;
        }
    }
    &.show-scroll-section{
        .site-header__redborder{
            bottom: 100vh;
        }
    }
}