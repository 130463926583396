.step-section {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: $white;
    transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);

    @include breakpoint(sm) {
        margin-top: 19.2vw;
        height: calc(100% - 19.2vw);
        overflow: hidden;
    }

    &.hide-step-section {
        visibility: hidden;
        opacity: 0;
    }

    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
        opacity: 0;
        visibility: hidden;

        @include breakpoint(sm) {
            overflow: auto;
        }

        &.current-screen {
            visibility: visible;
            opacity: 1;

            @include breakpoint(sm) {
                position: relative;
                z-index: 2;
            }
        }

        .beige-background, .beigedark-background {
            height: 48.125vh;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: 1;

            @include breakpoint(sm) {
                //position: relative;
                height: auto;
            }
        }

        .beige-background {
            @include breakpoint(sm) {
                margin-top: 0;
                background: none !important;
                bottom: initial;
                top: 0;
            }
        }

        .beigedark-background {
            height: 17.92vh;

            @include breakpoint(sm) {
                height: fit-content;
                display: inline-block;
                width: 100%;
                top: auto;
                bottom: 0;
                position: absolute;
            }
        }
    }

    &__inner {
        height: 100%;
        position: relative;

        @include breakpoint(sm) {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }

    &__qus-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
        padding-top: 5vh;

        @include breakpoint(sm) {
            padding: 8.6vw 4vw 20vw;
            height: auto;
        }

        @media only screen and (max-width: 480px) and (max-height: 767px) {
            padding: 7vw 4vw 0vw;
        }

        p {
            margin: 0;
            font-size: 25px;
            font-family: $default-book;

            @include breakpoint(sm) {
                line-height: normal;
                font-size: 3.5vw;
            }
        }
    }

    &__color-radio {
        height: 42px;
        width: 42px;
        position: relative;
        border-radius: 50%;
        margin-right: 12.4px;

        @include breakpoint(sm) {
            width: 8.14vw;
            height: 8.14vw;
            margin: 0 1.5vw;
        }

        &:last-child {
            margin-right: 0;
        }

        input {
            margin: 0;
            width: 100%;
            height: 100%;
            display: block;
            opacity: 0;
            cursor: pointer;
        }

        input:checked + svg {
            transform: scale(1);
            opacity: 1;
        }

        svg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
            transform: scale(0);
            opacity: 0;

            @include breakpoint(sm) {
                width: 3.5vw;
                height: auto;
            }
        }
    }

    &__choose-colors {
        width: 100%;
        display: inline-block;
        align-items: center;
        top: -32px;
        position: relative;

        @include breakpoint(sm) {
            top: auto;
            margin-bottom: 15vw;
            margin-top: -7vw;
        }

        @media only screen and (max-width: 480px) and (max-height: 767px) {
            margin-bottom: 9vw;
            margin-top: -9vw;
        }

        &_inner {
            display: inline-flex;
            align-items: center;
            background: $white;
            border-radius: 32px;
            padding: 11px 16px;

            @include breakpoint(sm) {
                display: inline-block;
                background: none;
                border-radius: 0;
                padding: 0;
            }
        }
    }

    &__choose-colors-cover {
        display: flex;
        align-items: center;

        @include breakpoint(sm) {
            display: inline-flex;
            background: $white;
            border-radius: 10vw;
            padding: 2.2vw 2vw;
            margin: 0 0 5vw;
        }
    }

    &__choose-colors-label {
        font-size: 16px;
        line-height: normal;
        font-family: $default-book;
        padding: 0 32px 0 46px;

        @include breakpoint(sm) {
            font-size: 3.75vw;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(sm) {
            position: relative;
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 6.3vw;
        }

        .solid-btn {
            position: absolute;
            //right: 81px;
            right: 5.3vw;
            //bottom: 45px;
            bottom: 4.7vh;
            z-index: 2;
            font-family: "Toyota Type";

            @include breakpoint(sm) {
                position: relative;
                right: auto;
                bottom: auto;
                display: inline-block;
                display: inline-block;
                margin-bottom: 8vw;
                width: 70vw;
                font-size: 3.7vw;
            }
        }

        .page-counter {
            text-align: center;
            width: 100%;
        }
    }

    &__choose-tick-label {
        position: relative;
        font-family: $default-book;
        background: $white;
        border-radius: 50px;
        -khtml-border-radius: 50px;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        padding: 16px 54px 22px 54px;
        min-width: 172px;
        transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
        opacity: 0.53;

        @include breakpoint(md) {
            padding: 16px 30px 18px 30px;
            min-width: 100px;
        }

        @include breakpoint(sm) {
            padding: 4.5vw 5vw 4.7vw 5vw;
            border-radius: 50vw;
        }

        @media only screen and (max-width: 639px) and (max-height: 767px) {
            padding: 3.5vw !important;
        }
    }

    &__choose-label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -34px;

        @include breakpoint(sm) {
            top: 0px;
            margin-bottom: 3vh;
            margin-top: 1vh;
        }

        &_item {
            margin: 10px 21.5px;
            position: relative;

            @include breakpoint(md) {
                margin: 10px 10px;
            }

            @include breakpoint(sm) {
                margin: 1vh auto;
                width: 90vw;
            }

            svg {
                position: absolute;
                left: 38.4px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
                transform: scale(0);
                opacity: 0;

                @include breakpoint(md) {
                    left: 28.4px;
                }

                @include breakpoint(sm) {
                    width: 3vw;
                    left: 8vw;
                    height: auto;
                }
            }

            span {
                font-size: 16px;
                line-height: 23px;

                @include breakpoint(sm) {
                    font-size: 3.7vw;
                    line-height: normal;
                }
            }

            input:checked + .step-section__choose-tick-label {
                transform: scale(1.15);
                padding: 16px 54px 22px 94px;
                //border: 4px solid #EFDDAE;
                opacity: 1;

                @include breakpoint(md) {
                    transform: scale(1.05);
                    padding: 16px 35px 20px 60px;
                }

                @include breakpoint(sm) {
                    transform: scale(1);
                    padding: 4.5vw 5vw 4.7vw 5vw;
                    outline: none; //3px solid #EFDDAE;
                }

                svg {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: 0;
                left: 0;
                top: 0;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
            }
        }
    }

    &__screen {
        @include breakpoint(sm) {
            background: #E6DDD2;

            &::before, &::after {
                content: "";
                position: absolute;
                top: 38.2vw;
                bottom: 0;
                left: 0;
                right: 0;
                background: #EEE4D8;
            }

            &::after {
                height: 30vh;
                background: #E6DDD2;
                bottom: 0;
                top: auto;
            }
        }

        &__img {
            position: relative;
            width: 80.8vh;
            top: 13.5vh;
            z-index: 2;
            display: inline-block;

            @include breakpoint(sm) {
                width: 90vw;
                margin-top: 15vw;
                top: auto;

                img {
                    width: 80%;
                }
            }
        }
    }

    &__screen-1 {
        @include breakpoint(sm) {
            padding-bottom: 6.7vw;
            background: $white;

            &::before, &::after {
                display: none;
            }
        }

        &__block-1 {
            position: absolute;
            left: 99px;
            top: 0;

            @include breakpoint(sm) {
                left: 0;
            }
        }

        .h1 {
            font-size: 9vw;
            //font-size: 150px;
            //line-height: 130px;
            line-height: 0.865em;
            font-weight: 300;
            color: $tuna;
            position: relative;
            z-index: 1;
            width: 50vw;
            top: 16.4vh;
            left: 15vh;
            transform: translate3d(0, 100px, 0) !important;
            visibility: hidden !important;
            opacity: 1 !important;

            @include breakpoint(lg) {
                font-size: 8vw;
            }

            @include breakpoint(sm) {
                top: 0;
                left: 0;
                padding: 12.6vw 0 7vw 5vw;
                font-size: 14.6vw;
                width: 80vw;
                line-height: 1em;
            }

            @media only screen and (max-width: 480px) and (max-height: 767px) {
                width: 68vw;
                font-size: 12vw;
            }
        }

        &__block-img {
            overflow: hidden;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: relative;

            @include breakpoint(sm) {
                width: 56.1vw;
                height: 47.5vw;
                order: 2;
            }

            @media only screen and (max-width: 480px) and (max-height: 767px) {
                width: 50.1vw;
                height: 42.5vw;
            }

            > img {
                width: calc(100% + 20px);
                max-width: calc(100% + 20px);
                position: relative;
                transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
            }
        }

        &__block-2 {
            position: absolute;
            right: 0;
            top: 0;
            height: 65.28vh;
            width: 48.6vw;

            @include breakpoint(sm) {
                position: relative;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin: 0 0 9vw;
            }
        }

        &__block-3 {
            position: absolute;
            right: 100%;
            top: 100%;
            height: 34.7vh;
            width: 34.7vh;
            //width: 52%;
            @include breakpoint(sm) {
                position: relative;
                top: auto;
                height: auto;
                width: auto;
                right: auto;
                height: 33.2vw;
                width: 33.2vw;
            }

            img {
                transition-delay: 0.5s;
            }

            .step-section__screen-1__block-img {
                @include breakpoint(sm) {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__caps-btn {
            position: absolute;
            bottom: 4.7vh;
            right: 4.4vw;
            text-align: right;

            @include breakpoint(sm) {
                position: relative;
                bottom: auto;
                right: auto;
                text-align: center;
                font-family: $default-book;
                line-height: normal;
            }

            p {
                font-size: 25px;
                margin: 0 0 52px;

                @include breakpoint(sm) {
                    font-size: 5.8vw;
                    margin: 0 0 12.3vw;
                    padding: 0 5vw;
                }

                @media only screen and (max-width: 480px) and (max-height: 767px) {
                    margin: 0 0 8.3vw;
                    font-size: 5vw;
                }
            }

            .solid-btn {
                @include breakpoint(sm) {
                    margin-bottom: 6.7vw;
                }
            }
        }

        &-lexus {
            @include breakpoint(sm) {
                padding-bottom: 6.7vw;
                background: $white;

                &::before, &::after {
                    display: none;
                }
            }

            &__block-1 {
                position: absolute;
                left: 48vw;
                top: 8vh;
                width: 35vw;

                .modelname {
                    font-size: 23vw;
                    font-weight: 400;
                    -webkit-text-stroke: .32vw $tuna;
                    -moz-text-stroke: .32vw $tuna;
                    text-stroke: .64vh $tuna;
                    color: transparent;
                    line-height: 1;
                }

                @include breakpoint(sm) {
                    left: 10vw;
                    //order: 2;
                    width: 80vw;
                    top: 26vh;

                    .modelname {
                        font-size: 30vw;
                        top: 33vh;
                    }
                }
            }

            .h1, .image {
                font-size: 5vh;
                width: 27vw;
                //font-size: 150px;
                //line-height: 130px;
                line-height: 0.865em;
                font-weight: 300;
                color: $tuna;
                position: absolute;
                z-index: 1;
                //width: 50vw;
                top: 62vh;
                left: 70vw;
                transform: translate3d(0, 100px, 0) !important;
                visibility: hidden !important;
                opacity: 1 !important;

                @include breakpoint(sm) {
                    top: 55vh;
                    left: 0;
                    padding: 0 10vw;
                    font-size: 3vh;
                    width: 100vw;
                    text-align: right;
                    line-height: 1em;
                }

                @media only screen and (max-width: 480px) and (max-height: 767px) {
                    top: 45vh;
                    left: 0;
                    padding: 0 10vw;
                    font-size: 5vh;
                    width: 100vw;
                    text-align: right;
                    line-height: 1em;
                }
            }

            .image {

                top: 10vh;

                @include breakpoint(sm) {
                    top: 0;
                }

                @media only screen and (max-width: 480px) and (max-height: 767px) {
                    top: 45vh;
                    width: 51vw;

                }
            }

            &__block-img {
                overflow: hidden;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: relative;

                @include breakpoint(sm) {
                    width: 100vw;
                    height: 40vh;
                    //order: 1;
                }

                @media only screen and (max-width: 480px) and (max-height: 767px) {
                    width: 100vw;
                    height: 40vh;
                }

                > img {
                    width: calc(100% + 20px);
                    max-width: calc(100% + 20px);
                    position: relative;
                    transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    left: 0;
                }
            }

            &__block-2 {
                position: absolute;
                left: 0;
                top: 0;
                height: 100vh;
                width: 65vw;

                @include breakpoint(sm) {
                    position: relative;
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin: 0 0 9vw;
                }
            }

            &__block-3 {
                position: absolute;
                right: 100%;
                top: 100%;
                height: 34.7vh;
                width: 34.7vh;
                //width: 52%;
                @include breakpoint(sm) {
                    position: relative;
                    top: auto;
                    height: auto;
                    width: auto;
                    right: auto;
                    height: 33.2vw;
                    width: 33.2vw;
                }

                img {
                    transition-delay: 0.5s;
                }

                .step-section__screen-1__block-img {
                    @include breakpoint(sm) {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &__caps-btn {
                position: absolute;
                bottom: 4.7vh;
                left: 70vw;
                text-align: left;
                padding-right: 2vw;

                @include breakpoint(sm) {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    text-align: center;
                    font-family: $default-book;
                    line-height: normal;
                    transform: translateX(-50%);
                    width: 100%;
                }

                p {
                    font-size: 24px;
                    margin: 0 0 52px;
                    line-height: 24px;

                    @include breakpoint(sm) {
                        font-size: 5.8vw;
                        margin: 0 0 12.3vw;
                        padding: 0 5vw;
                    }

                    @media only screen and (max-width: 480px) and (max-height: 767px) {
                        margin: 0 0 1.3vw;
                        font-size: 3.6vw;
                    }
                }

                .solid-btn {
                    @include breakpoint(sm) {
                        margin-bottom: 6.7vw;
                    }
                }
            }
        }
    }

    &__screen-2 {
        .car_auto_img {
            position: absolute;
            width: 100vh;
            height: auto;
            left: 50%;
            top: 45%;
            transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
            z-index: 1;
            background-repeat: no-repeat;
            background-size: cover;
            transform: translateX(-50%) translateY(-50%);
            background-position: center;
            z-index: 2;

            @include breakpoint(lg) {
                width: 52vh;
                top: 23% !important;
            }

            @include breakpoint(md) {
                width: 52vh;
                top: 23% !important;
            }

            @include breakpoint(sm) {
                width: 89vw;
                top: 36vw !important;

                img {
                    width: 80%;
                }
            }
        }

        h2 {
            font-size: 10vw;
            font-weight: 300;
            margin: 0;
            line-height: 1em;
            position: absolute;
            width: 100%;
            top: 3vh;

            @include breakpoint(md) {
                font-size: 17vw;
                top: 6vh;
            }

            @include breakpoint(sm) {
                position: relative;
                width: 100%;
                top: auto;
                margin: 12vw 0 20vw;
                font-size: 21.5vw;
            }

            @media only screen and (max-width: 480px) and (max-height: 767px) {
                margin: 12vw 0 14vw;
            }
        }

        &.current-screen {
            /*.car_auto_img {
                left: 50%;
            }*/
        }
    }
}
.squer-300{
    height: 300px;
    width: 300px;
    @include breakpoint(sm) {
        width: 40.7vw;
        height: 43.5vw;
    }
}
.back-link {
    position: absolute;
    left: 100px;
    bottom: 49px;
    text-decoration: underline;
    font-weight: 600;
    font-size: 16px;
    z-index: 2;
    bottom: 5.6vh;
    opacity: 0.3;

    @include breakpoint(sm) {
        font-size: 3vw;
        z-index: 2;
        bottom: 7.2vw;
        left: 5vw;
    }

    svg {
        position: relative;
        top: 4px;
        margin-right: 14px;

        @include breakpoint(sm) {
            width: 1.6vw;
            top: 1vw;
        }
    }
}
.page-counter{
    color: $tuna;
    opacity: 0.3;
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    //bottom: 54px;
    bottom: 5.6vh;
    @include breakpoint(sm) {
        position: relative;
        bottom: auto;
        order: 2;
        font-size: 3.2vw;
    }
}
.page-loaded{
    .step-section__screen-1__block-img > img{
        left: 0
    }
}
.step-section__screen{
    h1, h2{
        transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
        position: relative;
        transform: translate3d(0,200px,0);
        opacity: 0;
        visibility: hidden;
    }
    &.current-screen{
        h1, h2{
            opacity: 1;
            visibility: visible;
            transform: translate3d(0,40px,0);
        }
    }
}
.page-loaded {
    .step-section__screen-1 {
        .h1 {
            transform: translateZ(0px) !important;
            visibility: visible !important;
            opacity: 1 !important;
        }

        &-lexus {
            .h1 {
                transform: translateZ(0px) !important;
                visibility: visible !important;
                opacity: 1 !important;
            }

            .image {
                transform: translateZ(0px) !important;
                visibility: visible !important;
                opacity: 1 !important;
            }
        }
    }
}
.show-scroll-section{
    .e-magazine-vertical-section .e-magazine-section-1__left{
        .font-150{
            transform: translateZ(0px)!important;
            visibility: visible!important;
            opacity: 1!important;
        }
    }
}