@import 'mixin';
@import 'fonts';
@import 'color'; 
@import 'reset';
@import 'button';
@import 'header';  
@import 'nav';  
@import 'footer';
@import 'landing-page';
@import 'step-section';
@import 'e-magazine';
@import 'fancybox';

.centermessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

#components-reconnect-modal {
    display: none;
}

#components-reconnect-modal.components-reconnect-show,
#components-reconnect-modal.components-reconnect-failed,
#components-reconnect-modal.components-reconnect-rejected {
    display: block;
}