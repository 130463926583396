.solid-btn {
    border: 0;
    font-size: 2vh;
    line-height: normal;
    padding: 1.4vh 6.2vh;
    color: $white;
    background: $shark;
    text-align: center;
    display: inline-block;
    cursor: pointer;

    @include breakpoint(sm) {
        font-size: 3.75vw;
        padding: 4vw 10vw 4.6vw;
    }

    &:hover {
        background: $red;
    }

    &.solid-btn-56 {
        padding: 1.4vh 6.2vh;
        font-size: 2vh;
        line-height: 3vh;

        @include breakpoint(sm) {
            font-size: 3.7vw;
            line-height: normal;
            padding: 4vw 2vw 4.5vw;
        }
    }

    &:disabled {
        background: $shark;
        cursor: not-allowed;
    }
}
.red-btn{
    display: inline-block;
    width: auto;
    line-height: normal;
    padding: 1.4vh 6.2vh;
    color: #fff;
    text-transform: none;
    font-weight: normal;
    font-family: $default-book;
    font-size: 1.6vh;
    border: 0;
    background-color: $red;
    text-align: center;
    @include breakpoint(sm){
        font-size: 3.8vw;
        padding: 4vw 4vw 4.3vw;
    }
    &:hover{
        background-color: $white;
        color: $red;
    }
}

.campaign {
    .solid-btn-56 {
        margin-top: 2vh;
        width: 75%;
    }
}