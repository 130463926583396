* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}
progress{
    position: fixed;
    top: 19.1vw;
    left: 0;
    width: 100vw;
    z-index: 9;
    display: none;
    border-radius: 0 !important;
    border: 0 !important;
    height: 2.7vw !important;
    background: none;
    -webkit-appearance: none !important;
    @include breakpoint(sm) {
        display: block;
    }
    &::-webkit-progress-bar {
        background-color: transparent !important;
    }
    &::-webkit-progress-value {
        background-color: $red !important;
    }
}
html, body {
    margin: 0px;
    font-size: 1.75vh;
    line-height: 1.41em;
    // overflow-x: hidden;
    color: $tuna;
    height: 100%;
}
.drag-proxy {
    visibility: hidden;
    position: absolute;
}
.main{
    position: relative;
    width: 100%;
    height: 100%;
}
.height-100{
    height: 100%;
}
img {
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    outline: none;
    height: auto;
}
a {
    text-decoration: none;
    color: $dark-blue;
    @include transition (all 0.3s ease-in-out 0s);
}
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0 0 15px 0;
    line-height: normal;
}
h1 {
    font-size:63px;
    @include breakpoint(xl) {
        font-size: 60px;
    }
    @include breakpoint(lg) {
        font-size: 55px;
    }
    @include breakpoint(md) {
        font-size: 50px;
    }
    @include breakpoint(sm) {
        font-size: 45px;
    }
    @include breakpoint(xs) {
        font-size: 40px;
    }
    @include breakpoint(vs) {
        font-size: 35px;
    }
}
h2 {
    font-size: 55px;
    @include breakpoint(xl) {
        font-size: 50px;
    }
    @include breakpoint(lg) {
        font-size: 45px;
    }
    @include breakpoint(md) {
        font-size: 40px;
    }
    @include breakpoint(sm) {
        font-size: 35px;
    }
    @include breakpoint(xs) {
        font-size: 30px;
    }
    @include breakpoint(vs) {
        font-size: 25px;
    }
}
h3 {
    font-size:45px;
    @include breakpoint(xl) {
        font-size: 40px;
    }
    @include breakpoint(lg) {
        font-size: 35px;
    }
    @include breakpoint(md) {
        font-size: 30px;
    }
    @include breakpoint(sm) {
        font-size: 25px;
    }
    @include breakpoint(xs) {
        font-size: 20px;
    }
}
h4 {
    font-size:35px;
    @include breakpoint(xl) {
        font-size: 30px;
    }
    @include breakpoint(lg) {
        font-size: 25px;
    }
    @include breakpoint(md) {
        font-size: 20px;
    }
    @include breakpoint(sm) {
        font-size: 18px;
    }
}
h5 {
    font-size:25px;
    @include breakpoint(xl) {
        font-size:22px;
    }
    @include breakpoint(lg) {
        font-size: 22px;
    }
    @include breakpoint(md) {
        font-size: 18px;
    }
    @include breakpoint(sm) {
        font-size: 16px;
    }
}
h6 {
    font-size: 22px;
    @include breakpoint(xl) {
        font-size:20px;
    }
    @include breakpoint(lg) {
        font-size: 18px;
    }
    @include breakpoint(md) {
        font-size: 16px;
    }
    @include breakpoint(sm) {
        font-size: 14px;
    }
}
p {
    margin: 0 0 20px;
}
sup {
    font-size: 0.6em;
}
strong, b {
    
}
i,em{  }
i strong,em strong,i b,em b{ }
.overlay-link{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}
.bg-cover-center{
    background-size: cover;
    background-position: center;
}
.alignleft {
    float: left;
    margin: 0 20px 20px 0;
}
.alignright {
    float: right;
    margin: 0 0 20px 20px;
}
.alignnone {
    float: none;
    margin: 0 0 15px 0px;
    display: block;
}
.aligncenter {
    float: none;
    margin: 0 auto 15px auto;
    display: list-item;
}
.main-body {
    overflow: hidden;
    position: relative;
    clear: both;
    top: 0;
}
sup {
    font-size: 0.6em;
}
table {
    empty-cells: show;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 15px;
    border: 1px solid $black;
    td {
        padding: 0.5em 1em;
        border-width: 0 0 0 1px;
        font-size: inherit;
        margin: 0;
        overflow: visible;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
        &:first-child {
            border-left-width: 0;
        }
    }
    th {
        padding: 0.5em 1em;
        border-width: 0 0 0 1px;
        font-size: inherit;
        margin: 0;
        overflow: visible;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
        &:first-child {
            border-left-width: 0;
        }
    }
}
thead {
    text-align: left;
    vertical-align: bottom;
    background-color: $black;
    color: $black;
}
ul {
    margin: 0px;
    padding: 0px 0 15px 30px;
    li {
        margin: 0px;
        padding: 0 0 10px;
        list-style-type: disc;
        list-style-position: outside;
    }
}
ol {
    margin: 0px;
    padding: 0px 0 9px 32px;
    li {
        margin: 0px;
        padding: 0 0 10px;
        list-style-position: outside;
        list-style: decimal;
    }
}

.container{
    width: 1420px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    @include breakpoint(xl) {
        width: 1280px;
    }
    @include breakpoint(lg) {
        width: 1023px;
    }
    @include breakpoint(md) {
        width: 768px;
    }
    @include breakpoint(sm) {
        width: 640px;
    }
    @include breakpoint(xs) {
        width:480px;
    }
    @include breakpoint(vs) {
        width: 375px;
        padding: 0 15px;
    }
    .container{
        @media (min-width:768px){
            width: 100%!important;
            padding: 0;
        }
    }
}
.text-center{
    text-align: center;
}
.no-js {
    #loader {
        display: none;
    }
}
.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}
.section {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    vertical-align: top;
    width: 100%;
    position: relative;
}
::-webkit-input-placeholder {

}
.dawnPink-bg{
    background: $dawnPink;
}
.dawnPink{
    background: $dawnPink;
}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    > div{
        padding-left: 15px;
        padding-right: 15px;
    }
}
.col-4{
    width: 33.33%;
    @include breakpoint(sm) {
        width: 100%;
    }   
}

.image-move {
    position: relative;
    > img{

    }
}
.heading-animation {
    position: relative;
    top: 100px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
    transition-delay: 0.5s;
    @include breakpoint(sm) {
        top: 0;
        visibility: visible;
        opacity: 1;
    }
    &.enable{
        visibility: visible;
        opacity: 1;
        top: 0;
    }
}
