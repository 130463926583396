$white: #ffffff;
$black: #000000;
$blackPearl: #050A23;
$tuna: #3B3B42;
$red: #d40605;
$dawnPink: #F2EBE2;
$shark: #282830;

$gray:#878786;
$light-gray:#f5f5f5;
$yellow:#ffd305;
$dark-blue:#061b2b;
