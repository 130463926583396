@font-face {
    font-family: 'Toyota Type';
    src: url('fonts/ToyotaType-LightIt.woff2') format('woff2'),
        url('fonts/ToyotaType-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Toyota Type';
    src: url('fonts/ToyotaType-Regular.woff2') format('woff2'),
        url('fonts/ToyotaType-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Toyota Type';
    src: url('fonts/ToyotaType-Light.woff2') format('woff2'),
        url('fonts/ToyotaType-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Toyota Type';
    src: url('fonts/ToyotaType-Bold.woff2') format('woff2'),
        url('fonts/ToyotaType-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; 
}
@font-face {
    font-family: 'Toyota Type';
    src: url('fonts/ToyotaType-Semibold.woff2') format('woff2'),
        url('fonts/ToyotaType-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'toyota_typebook';
    src: url('fonts/toyotatype-book.woff2') format('woff2'),
         url('fonts/toyotatype-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'NobelWGL';
    src: url('fonts/NobelWGL-Book.eot');
    src: url('fonts/NobelWGL-Book.eot?#iefix') format('embedded-opentype'), url('fonts/NobelWGL-Book.woff2') format('woff2'), url('fonts/NobelWGL-Book.woff') format('woff'), url('fonts/NobelWGL-Book.ttf') format('truetype'), url('fonts/NobelWGL-Book.svg#NobelWGL-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NobelWGL';
    src: url('fonts/NobelWGL-Bold.eot');
    src: url('fonts/NobelWGL-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/NobelWGL-Bold.woff2') format('woff2'), url('fonts/NobelWGL-Bold.woff') format('woff'), url('fonts/NobelWGL-Bold.ttf') format('truetype'), url('fonts/NobelWGL-Bold.svg#NobelWGL-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NobelWGL';
    src: url('fonts/NobelWGL-Light.eot');
    src: url('fonts/NobelWGL-Light.eot?#iefix') format('embedded-opentype'), url('fonts/NobelWGL-Light.woff2') format('woff2'), url('fonts/NobelWGL-Light.woff') format('woff'), url('fonts/NobelWGL-Light.ttf') format('truetype'), url('fonts/NobelWGL-Light.svg#NobelWGL-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icons';
    src: url('fonts/icons.eot');
    src: url('fonts/icons.eot#iefix') format('embedded-opentype'), url('fonts/icons.woff2') format('woff2'), url('fonts/icons.woff') format('woff'), url('fonts/icons.ttf') format('truetype'), url('fonts/icons.svg#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}


 
$default-font:'Toyota Type';
$default-book: 'toyota_typebook';

