.font-70 {
    font-size: 6.5vh;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;

    @include breakpoint(sm) {
        font-size: 14vw;
        line-height: 1em;
    }
}

.font-80 {
    font-size: 8.5vh;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;

    @include breakpoint(sm) {
        font-size: 14vw;
        line-height: 1em;
    }
}
.font-100{
    font-size: 10.5vh;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;
    @include breakpoint(sm){
        font-size: 16vw;
        line-height: 1em;
    }
}
.font-110{
    font-size: 11.45vh;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;
    @include breakpoint(sm){
        font-size: 15.7vw;
        line-height: 1em;
    }
}
.font-120{
    font-size: 12.35vh;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;
    @include breakpoint(sm){
        font-size: 16vw;
        line-height: 1em;
    }
}
.font-130{
    font-size: 13.4vh;
    //text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;

}
.font-140{
    font-size: 140px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 0.84em;
}
.font-150{
    font-size: 10vh; //15.55vh;
    line-height: 0.867em;
    font-weight: 300;
    text-transform: uppercase;
    @include breakpoint(sm){
        font-size: 16vw;
        line-height: 1em;
    }
}
.color-sqauare{
    width: 30.7vh;
    height: 30.7vh;
    position: absolute;
    z-index: -1;
    &.bottom-left{
        bottom: 0;
        left: 0;
    }
}
.parallax-image{
    width: 150%;
    max-width: unset;
    object-fit: cover;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include breakpoint(sm){
        position: static;
        width: 100%;
        max-width: 100%;
    }
}
.section-menu{
    padding: 0 0 0 2%;
    width: 100%;
    margin: 6.5vh 0 0;
    @include breakpoint(sm){
        padding: 0;
        margin: 10.4vw 0 0;
    }
    li{
        list-style: none;
        width: 95%;
        font-size: 2.55vh;
        color: $tuna;
        font-weight: 300;
        text-transform: uppercase;
        padding: 0;
        margin: 0 0 3.23vh;
        padding-right: 14%;
        position: relative;
        counter-increment: my-counter;
        line-height: 1em;
        @include breakpoint(sm){
            width: 100%;
            font-size: 4.7vw;
            font-family: $default-book;
            margin: 0 0 5.8vw;
        }
        &::before{
            content: "0" counter(my-counter);
            color: $red;
            font-weight: bold;
            margin-right: 6%;
            @include breakpoint(sm){
                margin-right: 5%;
            }
        }
        &::after{
            position: absolute;
            content: '';
            width: 42px;
            height: 1px;
            background-color: $tuna;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
            @include breakpoint(sm){
                right: 0;
                width: 8vw;
            }
        }
        &:hover{
            a{
                color: $red;
            }
            &::after{
                background-color: $red;
            }
        }
    }
}

.e-magazine-vertical-section {
    height: 0;
    position: relative;
    overflow-x: hidden;
    background: $white;

    .wrapper {
        display: flex;
        flex-wrap: nowrap;
        scroll-snap-type: x mandatory;

        @include breakpoint(sm) {
            flex-wrap: wrap;
        }
    }

    .e-magazine-section {
        height: 100vh;
        display: flex;
        flex-shrink: 0;
        scroll-snap-align: start;
        position: relative;

        @include breakpoint(sm) {
            width: 100% !important;
            height: auto !important;
        }

        .emagazine-wrap {
            display: flex;
            flex-wrap: wrap;
            width: inherit;

            @include breakpoint(sm) {
                width: 100% !important;
            }
        }

        &-1 {
            padding: 12.7vh 0 0 11.4vh;
            width: 160.42vh;
            position: relative;

            @include breakpoint(sm) {
                width: 100%;
                padding: 0;
            }

            .emagazine-wrap {
                width: 100%;

                @include breakpoint(sm) {
                    justify-content: flex-end;
                }
            }

            .color-sqauare {
                width: 30.7vh;
                height: 30.7vh;

                @include breakpoint(sm) {
                    width: 40.7vw;
                    height: 43.5vw;
                    top: 0;
                    bottom: auto;
                    border-top: 0 solid $red;
                }
            }

            .modelname {
                font-size: 28vh;
                font-weight: 400;
                -webkit-text-stroke: .32vw $tuna;
                -moz-text-stroke: .32vw $tuna;
                text-stroke: .64vh $tuna;
                color: transparent;
                line-height: 1;
                position: absolute;
                left: 8vh;
                top: -18vh;
                z-index: 100;
            }

            &__left {
                width: 80.7vh;
                line-height: 1.4em;

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 17.4vw 4.95vw 9.8vw;
                }

                .font-150 {
                    word-wrap: break-word;
                    margin: 0 0 6.6vh;
                    transform: translate3d(0, 100px, 0) !important;
                    visibility: hidden !important;
                    opacity: 1 !important;
                    transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);
                    transition-delay: 0.5s;

                    @include breakpoint(sm) {
                        margin: 0 0 9.1vw;
                    }
                }

                p {
                    font-family: $default-book;
                    padding: 0 0 0 0;
                    width: 85%;
                    font-size: 2.93vh;
                    line-height: 1.43em;

                    @include breakpoint(sm) {
                        padding: 0;
                        width: 101%;
                        font-size: 5.8vw;
                        line-height: 1.35em;
                        letter-spacing: -0.01em;
                    }
                }
            }

            &__right {
                width: 46.9vh;
                height: 46.9vh;
                position: relative;
                top: 22.3vh;
                background-position: center;
                background-size: cover;

                @include breakpoint(sm) {
                    top: 0;
                    width: 56vw;
                    height: 47.5vw;
                }
            }

            .scroll-navigate {
                width: 94%;
                text-align: left;
                padding: 5.5vw 0 0;

                @include breakpoint(sm) {
                    display: none;
                }

                a {
                    font-family: $default-book;
                    font-size: 2.9vh;
                    display: inline-block;
                    vertical-align: middle;

                    span {
                        display: inline-flex;
                        vertical-align: middle;
                        margin: 0 25px 0 0;
                    }
                }
            }
        }

        &-2 {
            width: 92.396vh;

            @include breakpoint(sm) {
                padding: 15.5vw 0 0;
            }

            .emagazine-wrap {
                width: 100%;

                .inhoud_hero_img {
                    height: 100vh;
                    width: 100%;
                    position: relative;
                    overflow: hidden;

                    @include breakpoint(sm) {
                        height: 100vw;
                    }
                }
            }
        }

        &-3 {
            .emagazine-wrap {
                counter-reset: my-counter;
                padding: 10.5vh 10.5vh 6vh;
                justify-content: space-between;
                //width: 170.23vh;

                @include breakpoint(sm) {
                    padding: 17.3vw 5vw 15.3vw;
                    width: 100%;
                }

                .inhoud-cols {
                    width: 45.3vh;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    flex-direction: column;

                    @include breakpoint(sm) {
                        width: 100%;
                    }

                    &__2 {
                        .section-menu {
                            margin: 0 0 11.3vh;

                            @include breakpoint(sm) {
                                margin: 0 0 0;
                            }
                        }
                    }

                    &__3 {
                        .section-menu {
                            margin: 15vh 0 0;

                            @include breakpoint(sm) {
                                margin: 0 0 0;
                            }
                        }
                    }

                    .inhoud-box-top {
                        width: 100%;

                        h2 {
                            @include breakpoint(sm) {
                                margin: 0;
                            }
                        }
                    }

                    .inhoud-box-bottom {
                        width: 45.2vh;
                        height: 45.2vh;

                        @include breakpoint(sm) {
                            display: none;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    &:nth-child(even) {
                        .inhoud-box-top {
                            order: 2;
                        }

                        .inhoud-box-bottom {
                            order: 1;
                        }
                    }
                }
            }
        }

        &-4 {
            background: $shark;

            .emagazine-wrap {
                //width: 208.23vh;



                .evolutie_left {
                    width: 73.7vh;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    height: 100%;
                    padding: 0 6.8vh 8vh;
                    position: relative;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: auto;
                        padding: 20vw 5vw 10vw;
                    }

                    h2 {
                        color: $white;
                        line-height: 0.93em;

                        @include breakpoint(sm) {
                            line-height: 1em;
                        }

                        span {
                            color: $red;
                        }
                    }

                    .evolutie_text {
                        position: absolute;
                        top: 9.5vh;
                        left: 8vh;
                        font-family: $default-book;
                        color: $white;
                        font-size: 2.6vh;
                        line-height: 3.2vh;
                        width: 65vh;
                        z-index: 1;

                        @include breakpoint(sm) {
                            position: relative;
                            width: 100%;
                            padding: 0 0;
                            left: 0;
                            top: 0;
                            font-size: 4.6vw;
                            line-height: 1.4em;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 36vh;
                        height: 1.4vh;
                        background-color: $red;
                        top: 0;
                        right: 0;

                        @include breakpoint(sm) {
                            width: 41vw;
                            right: auto;
                            left: 0;
                        }
                    }
                }

                .evolutie_right {
                    width: 134.52vh;
                    height: 100vh;
                    position: relative;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: 133.3vw;
                    }

                    .evolutie_hero {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                    }
                }
            }

            .red-btn, .solid-btn {
                margin-top: 4vh;
            }
        }

        &-24 {
            background: $shark;

            .emagazine-wrap {
                width: 208.23vh;

                .evolutie_right {
                    width: 73.7vh;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    height: 100%;
                    padding: 0 6.8vh 8vh;
                    position: relative;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: auto;
                        padding: 20vw 5vw 10vw;
                    }

                    h2 {
                        color: $white;
                        line-height: 0.93em;

                        @include breakpoint(sm) {
                            line-height: 1em;
                        }

                        span {
                            color: $red;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 36vh;
                        height: 1.4vh;
                        background-color: $red;
                        top: 0;
                        right: 0;

                        @include breakpoint(sm) {
                            width: 41vw;
                            right: auto;
                            left: 0;
                        }
                    }

                    .evolutie_text {
                        position: absolute;
                        top: 9.5vh;
                        left: 8vh;
                        font-family: $default-book;
                        color: $white;
                        font-size: 2.6vh;
                        line-height: 3.2vh;
                        width: 57vh;
                        z-index: 1;

                        @include breakpoint(sm) {
                            position: relative;
                            top: unset;
                            width: 100%;
                            padding: 0;
                            bottom: 4vw;
                            left: 0;
                            font-size: 4.6vw;
                            line-height: 1.4em;
                            margin-top: 7vh;
                        }
                    }
                }

                .evolutie_left {
                    width: 134.52vh;
                    height: 100vh;
                    position: relative;
                    padding: 0;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: 80vw;
                    }

                    .evolutie_hero {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                    }
                }
            }
        }

        &-5 {
            width: 88.2299vh;

            .emagazine-wrap {
                display: block;
                padding: 6.3vh 8.3vh;

                @include breakpoint(sm) {
                    padding: 18.1vw 0 15.5vw;
                }

                h2 {
                    font-weight: 300;
                    font-size: 13.55vh;
                    line-height: 1em;
                    margin: 0 0 6vh;

                    @include breakpoint(sm) {
                        font-size: 16vw;
                        line-height: 1em;
                        padding: 0 5vw;
                        margin: 0 0 9.2vw;
                    }
                }

                .exterieur__block-img {
                    width: 96.5%;
                    height: 49.8vh;
                    margin: 0 auto 5.7vh;
                    position: relative;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: 100vw;
                        margin: 0 0 11vw;
                    }
                }

                p {
                    padding: 0 2%;

                    @include breakpoint(sm) {
                        padding: 0 6vw;
                        line-height: 1.8em;
                        font-size: 3.7vw;
                    }
                }
            }
        }

        &-6 {
            width: 160vh;
            position: relative;
            overflow: hidden;
        }

        &-7 {
            width: 169.584vh;
            position: relative;
            overflow: hidden;

            .emagazine-wrap {
                padding: 4.7vh 5vh 4vh 5vh;
                align-items: center;

                @include breakpoint(sm) {
                    padding: 0;
                    align-items: flex-start;
                }
            }

            .oog-text-block {
                width: 42.55%;
                padding-bottom: 4.8vh;

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 18.5vw 4.5vw 20.55vw;
                }

                h2 {
                    font-size: 13.5vh;
                    font-weight: 300;
                    line-height: 1.08em;
                    margin: 0 0 11.8vh -1.5vh;
                    position: relative;

                    @include breakpoint(sm) {
                        font-size: 16vw;
                        line-height: 1em;
                        margin: 0 0 7vw;
                    }


                    .accent {
                        color: $red;
                    }

                    .intent1 {
                        padding-left: 7.5vh;

                        @include breakpoint(sm) {
                            padding: 0;
                        }
                    }

                    .intent2 {
                        padding-left: 15vh;

                        @include breakpoint(sm) {
                            padding: 0;
                        }
                    }
                }

                .oog-text-p {
                    float: right;
                    width: 55.5%;
                    right: 16.3%;
                    position: relative;
                    padding-left: 4.5vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        right: 0;
                        float: none;
                        display: block;
                        padding: 0;
                        line-height: 1.8em;
                    }

                    p {
                        margin: 0;
                    }

                    &::before {
                        content: "";
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background: $red;
                        position: absolute;

                        @include breakpoint(sm) {
                            display: none;
                        }
                    }
                }
            }

            .oog-img-main {
                width: 56.45%;
                display: flex;
                flex-wrap: wrap;

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 0;
                }

                .oog-img {
                    width: 43.9vh;
                    height: 45.52vh;
                    position: relative;
                    overflow: hidden;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: auto;
                        padding: 100vw 0 0;
                    }

                    &:hover {
                        .oog-img-caps {
                            left: 0;
                        }
                    }
                }

                .oog-img-caps {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    height: 100%;
                    width: 100%;
                    background: $shark;
                    color: $white;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 3.5vh 6vh 6vh;
                    transition: all .3s cubic-bezier(0.02, 0.21, 0.23, 0.99);

                    @include breakpoint(sm) {
                        position: static;
                        width: 100%;
                        height: auto;
                        padding: 9vw 12vw;
                    }

                    h3 {
                        font-size: 20px;
                        font-family: $default-book;
                        font-weight: normal;
                        margin: 0 0 21px;

                        @include breakpoint(sm) {
                            font-size: 5.9vw;
                            margin: 0 0 8.5vw;
                        }
                    }

                    p {
                        margin: 0;
                        font-family: $default-book;
                        line-height: 20px;

                        @include breakpoint(sm) {
                            line-height: 1.95em;
                            font-size: 3.8vw;
                        }
                    }
                }
            }
        }

        &-8 {
            width: 293vh;
            position: relative;
            overflow: hidden;

            .emagazine-wrap {
                .perfect__left {
                    width: 130vh;
                    height: 100%;
                    overflow: hidden;
                    position: relative;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: auto;
                        padding: 16vw 5vw;
                    }
                }

                .perfect__right {
                    width: 163vh;
                    background-position: right bottom;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-items: flex-start;

                    @include breakpoint(sm) {
                        width: 100%;
                        background-position: bottom right;
                        background-size: 300%;
                    }

                    &__gray_box {
                        width: 64vh;
                        background-color: $shark;
                        position: absolute;
                        bottom: 11.4vh;
                        left: -11.6vh;
                        padding: 6vh 6.8vh;
                        z-index: 1;

                        @include breakpoint(sm) {
                            position: static;
                            width: 100%;
                            padding: 18vw 5vw 20vw;
                        }

                        h2 {
                            color: $white;
                            text-transform: uppercase;
                            font-size: 6.45vh;
                            font-weight: 300;
                            line-height: 7.85vh;
                            margin: 0 0 3.6vh;

                            @include breakpoint(sm) {
                                font-size: 16vw;
                                line-height: 1em;
                                margin: 0 0 8vw;
                                word-wrap: break-word;
                            }
                        }

                        p {
                            color: $red;
                            font-size: 3.65vh;
                            line-height: 4.6vh;

                            @include breakpoint(sm) {
                                font-size: 5.6vw;
                                line-height: 1.4em;
                            }

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                    &__top_col {
                        width: 136vh;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        position: relative;

                        .perfect_acc_result {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            transition: all .8s;
                            opacity: 0;
                            visibility: hidden;



                            &.pr-show-acc {
                                position: static;
                                opacity: 1;
                                visibility: visible;

                                .perfect__right__top_col__img {
                                    transform: translateX(0);
                                    opacity: 1;
                                    visibility: visible;
                                }

                                .perfect__right__top_col__text {
                                    transform: translateX(0);
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            &.pr-start-acc {
                                position: static;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }

                        &__img {
                            width: 75.4vh;
                            height: 41.2vh;
                            transition: all .7s;
                            transform: translateX(-20px);
                            opacity: 0;
                            visibility: hidden;
                            transition-delay: .4s;

                            @include breakpoint(sm) {
                                width: 100%;
                                height: 67vw;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        &__text {
                            width: 60vh;
                            padding: 6.2vh 8vh 6.2vh 10vh;
                            transition: all .7s;
                            transform: translateX(20px);
                            opacity: 0;
                            visibility: hidden;
                            transition-delay: .4s;

                            @include breakpoint(sm) {
                                width: 100%;
                                padding: 8vw 6vw 0;
                                overflow: hidden;
                                height: 68vmin;
                            }

                            h3 {
                                font-size: 2.62vh;
                                margin: 0 0 2.3vh;

                                @include breakpoint(sm) {
                                    font-size: 5.9vw;
                                    line-height: 1.4em;
                                    margin: 0 0 5vw;
                                }
                            }

                            p {
                                line-height: 1.41em;

                                @include breakpoint(sm) {
                                    line-height: 1.95em;
                                    font-size: 3.75vw;
                                    height: 40vmin;
                                }

                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }
                    }

                    &__bottom_acc {
                        width: 90vh;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        margin: 0 10vh 0;

                        @include breakpoint(sm) {
                            width: 100%;
                            margin: 0 0 3.5vw;
                        }

                        &__img {
                            width: auto;
                            position: relative;

                            .acc_spot {
                                position: absolute;
                                background-color: $white;
                                width: 3.96vh;
                                height: 3.96vh;
                                border-radius: 100%;
                                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.33);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: $default-book;
                                font-size: 4vh;
                                cursor: pointer;

                                @include breakpoint(sm) {
                                }

                                span {
                                    display: inline-block;
                                    position: relative;
                                    top: -0.4vh;
                                }

                                &_1 {
                                    top: 22.5vh;
                                    left: 4.5vh;

                                    @include breakpoint(sm) {
                                        top: 28vw;
                                        left: 3vw;
                                    }
                                }

                                &_2 {
                                    top: 3.3vh;
                                    left: 43.8vh;

                                    @include breakpoint(sm) {
                                        top: 1.3vw;
                                        left: 37.8vw;
                                    }
                                }

                                &_3 {
                                    bottom: 10.1vh;
                                    left: 46.4vh;

                                    @include breakpoint(sm) {
                                        bottom: 7vw;
                                        left: 41.4vw;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.campaign {
                .perfect__right__bottom_acc {
                    width: 110vh;
                    margin: 0 10vh 0;

                    @include breakpoint(sm) {
                        margin: -1vh -2vh 0;
                    }
                }

                .perfect__right__top_col__text p {
                    @include breakpoint(sm) {
                        font-size: 3vw;
                    }
                }
            }
        }

        &-9 {
            width: 130.21vh;
            position: relative;
        }

        &-12 {
            width: 268.709vh;

            .oog-text-block {
                width: 30.65%;
                padding: 0 0 0.6vh;

                @include breakpoint(sm) {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 18vw 5vw;
                }

                h2 {
                    margin: 0;
                    font-size: 12.5vh;
                    line-height: 0.92em;

                    @include breakpoint(sm) {
                        font-size: 16vw;
                        line-height: 1em;
                        margin: 0 0 7vw;
                        order: 1;
                    }

                    .center-line {
                        padding-left: 3.7vh;

                        @include breakpoint(sm) {
                            padding-left: 0;
                        }
                    }

                    .red-text {
                        padding-left: 7vh;

                        @include breakpoint(sm) {
                            padding-left: 0;
                        }
                    }
                }

                .oog-text-p {
                    width: 46.6%;
                    right: 7.2%;
                    margin: 0 0 19vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        right: 0;
                        margin: 0;
                        order: 2;
                    }
                }
            }

            .oog-img-main {
                width: 69.35%;

                @include breakpoint(sm) {
                    width: 100%;
                }
            }
        }

        &-13 {
            width: 115.834vh;

            @include breakpoint(sm) {
                width: 100%;
                height: auto;
                padding: 16vw 5vw;
            }
        }

        &-14 {
            color: $white;
            background: $shark;

            .emagazine-wrap {
                width: 220.67vh;

                @include breakpoint(sm) {
                    display: flex;
                    flex-wrap: wrap;
                }

                .evolutie_left {
                    width: 89.774vh;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 13vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        padding-top: 20vw;
                        order: 1;
                    }

                    h2 {
                        line-height: 0.93em;

                        @include breakpoint(sm) {
                            order: 1;
                            line-height: 1em;
                            margin: 0 0 9vw;
                        }
                    }

                    .soeple-p-text {
                        @include breakpoint(sm) {
                            order: 2;
                            font-size: 4.65vw;
                            line-height: 1.42em;
                        }
                    }
                }

                .evolutie_right {
                    width: 130.896vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        order: 2;
                    }
                }
            }

            .soeple-p-text {
                width: 37.5vh;

                @include breakpoint(sm) {
                    width: 100%;
                }
            }
        }

        &-15 {
            width: 169.59vh;
            padding: 5.8vh 19.6vh 7.8vh 17vh;

            @include breakpoint(sm) {
                padding: 0;
            }

            &__inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                @include breakpoint(sm) {
                    padding: 16.6vw 4.7vw;
                }
            }

            &__heading {
                float: right;
                width: auto;
                position: relative;
                right: 4.1vh;

                @include breakpoint(sm) {
                    right: 0;
                    float: none;
                    display: block;
                    padding: 0 0 31vw;
                }

                h3 {
                    font-size: 8.34vh;
                    font-weight: 300;
                    color: $blackPearl;
                    line-height: 1em;
                    margin: 0 0 2.2vh;

                    @include breakpoint(sm) {
                        font-size: 16vw;
                        margin: 0 0 7.5vw;
                    }
                }

                p {
                    color: $red;
                    font-size: 3.13vh;
                    font-family: $default-book;
                    line-height: 1.2em;
                    margin: 0;

                    @include breakpoint(sm) {
                        font-size: 6vw;
                    }
                }
            }

            &__cards {
                justify-content: space-between;
                align-items: flex-end;
                color: $white;
            }

            &__card {
                width: 100%;
                padding: 3vh 3.7vh 4.6vh 3.9vh;
                position: relative;

                ul {
                    display: none;
                }

                .solid-btn {
                    display: none;
                    margin-top: 4vh;
                }

                @include breakpoint(sm) {
                    padding: 9.5vw 10vw;
                    margin: 0 0 25vw;
                }

                .car_render {
                    display: none;
                }

                &.yellow-gradient {
                    background: linear-gradient(135deg, rgba(187,148,55,1) 0%,rgba(234,191,34,1) 100%);
                    min-height: 50vh;

                    .car_render {
                        display: block;
                        position: absolute;
                        top: -100px;
                        left: 50%;
                        width: 60%;
                        transform: translateX(-50%);
                    }

                    ul {
                        display: block;
                    }

                    .solid-btn {
                        display: block;
                        padding: 20px 10px;
                        color: #fff;
                        background-color: rgb(40, 40, 48);


                        &:hover {
                            background-color: #fff;
                            color: #061b2b;
                        }
                    }
                }

                label {
                    display: inline-block;
                    background: $white;
                    font-size: 1.47vh;
                    color: #C69E32;
                    border-radius: 50vh;
                    padding: 0.4vh 1.9vh 0.9vh;
                    margin: 2.7vh 0 1vh;
                    line-height: normal;

                    @include breakpoint(sm) {
                        font-size: 3.7vw;
                        border-radius: 100vw;
                        padding: 1.6vw 5vw;
                        margin: 5.5vw 0 2.6vw;
                    }
                }

                label + h4 {
                    margin: 0 0 3.8vh;

                    @include breakpoint(sm) {
                        margin: 0 0 10.4vw;
                    }
                }

                h4 {
                    font-size: 2.6vh;
                    font-family: $default-book;
                    font-weight: normal;
                    margin: 0 0 6.2vh;
                    padding: 0 0 3.5vh;
                    position: relative;

                    @include breakpoint(sm) {
                        font-size: 5.95vw;
                        padding: 0 0 10vw;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 8.35vh;
                        height: 2px;
                        background: $white;
                    }
                }

                p {
                    margin: 0 0 1.3vh;

                    @include breakpoint(sm) {
                        line-height: 1.7em;
                        font-size: 3.75vw;
                        margin: 0 0 5.7vw;
                    }
                }

                ul {
                    padding: 1vh 0 0 0;
                    margin: 0;

                    li {
                        padding: 0 0 1.7vh 3.7vh;
                        margin: 0;
                        list-style: none;
                        background: url(../images/tick.svg) no-repeat top 0.8vh left / 1.7vh;

                        @include breakpoint(sm) {
                            padding: 0 0 5vw 9.7vw;
                            background: url(../images/tick.svg) no-repeat top 1.7vw left/4vw;
                        }

                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        &-16 {
            width: 141.36vh;
        }

        &-17 {
            width: 150vh;
            padding: 10.5vh 6vh 7vh;
            background: none;

            @include breakpoint(sm) {
                width: 100%;
                padding: 19vw 5vw 7vw;
            }

            .emagazine-wrap {
                align-items: flex-start;
            }

            .title {
                width: 100%;
                margin: 0 0 4.5vh;

                @include breakpoint(sm) {
                    margin: 0 0 6vw;
                }

                h2 {
                    @include breakpoint(sm) {
                        margin: 0 0 6.8vw;
                    }
                }

                h3 {
                    display: none;

                    @include breakpoint(sm) {
                        display: block;
                        font-size: 5.6vw;
                        line-height: 1.4em;
                    }
                }
            }

            h3 {
                font-size: 3.7vh;
                line-height: 5vh;
                color: $red;

                @include breakpoint(sm) {
                    display: none;
                    font-size: 5.6vw;
                    line-height: 1.4em;
                }
            }

            .duurzaam-cols {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &__left {
                    width: 48.6%;
                    overflow: hidden;
                    position: relative;
                    height: 68vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        height: auto;
                    }
                }

                &__right {
                    width: 41.8%;
                    padding: 0 7vh 0 4.6vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        padding: 11.5vw 0vw 0;
                    }

                    &__text {
                        width: 100%;
                        position: relative;
                        margin: 0 0 6.3vh;

                        @include breakpoint(sm) {
                            font-size: 3.75vw;
                            line-height: 1.75em;
                            margin: 0;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            left: -4.6vh;
                            top: 3vh;
                            width: 1px;
                            bottom: 4vh;
                            background-color: $red;
                        }

                        p {
                            margin: 0 0 25px;

                            @include breakpoint(sm) {
                                margin: 0 0 7vw;
                            }
                        }
                    }
                }
            }
        }

        &-18 {
            width: 141.2vh;

            @include breakpoint(sm) {
                width: 100%;
                height: auto;
                padding: 16vw 5vw;
            }
        }

        &-19 {
            width: 152.61vh;
            padding: 75px 50px;

            @include breakpoint(sm) {
                padding: 0;
            }

            &__lh {
                width: 77.13vh;

                @include breakpoint(sm) {
                    width: 100%;
                }

                h2 {
                    font-size: 12.5vh;
                    font-weight: 300;
                    line-height: 0.92em;
                    padding: 0 0 0 8vh;

                    @include breakpoint(sm) {
                        font-size: 16vw;
                        padding: 0 5vw;
                        line-height: 1em;
                        margin: 0 0 5.7vw;
                        order: 1;
                    }
                }
            }

            &__rh {
                width: 64vh;
                padding-left: 7vh;
                padding-top: 0;

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 4vw 5vw;
                    font-size: 3.8vw;
                }

                p {
                    line-height: 1.46em;

                    sub {
                        line-height: 1;
                        font-size: 1.1vh;
                    }

                    @include breakpoint(sm) {
                        line-height: 1.75em;
                    }
                }
            }

            &__img {
                width: 100%;
                height: 49vh;
                margin: 0 0 8.6vh;

                @include breakpoint(sm) {
                    height: auto;
                    margin: 0 0 7vw;
                }
            }
        }

        &-20 {
            width: 111.25vh;
            position: relative;

            @include breakpoint(sm) {
                padding: 10.3vw 0 0;
                flex-wrap: wrap;
                display: grid;
            }

            .chr_img {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;

                @include breakpoint(sm) {
                    height: auto;
                    order: 2;
                }
            }

            .tot-10-caps-block {
                position: absolute;
                z-index: 1;
                width: 92.84vh;
                background: $shark;
                color: $white;
                bottom: 8vh;
                right: 73.5vh;
                padding: 10.7vh 6vh 9.7vh 8.41vh;

                @include breakpoint(sm) {
                    position: relative;
                    width: 100%;
                    padding: 16vw 0 11vw;
                    bottom: 0;
                    right: 0;
                    order: 1;
                    text-align: center;
                }

                &__inner {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                &__img {
                    width: 35.1vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        padding: 0vw 15vw;
                    }

                    img {
                        width: 26.45vh;

                        @include breakpoint(sm) {
                            width: 100%;
                        }
                    }
                }

                &__caps {
                    position: relative;
                    width: 42.3vh;
                    padding: 0 0 0 6vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        padding: 20.2vw 5vw 0;
                        text-align: left;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 1px;
                        background-color: $red;

                        @include breakpoint(sm) {
                            top: 11vw;
                            width: 70%;
                            height: 1px;
                            bottom: auto;
                            margin: 0 auto;
                            left: 0;
                            right: 0;
                        }
                    }

                    p {
                        line-height: 1.48em;
                        margin: 0 0 3.3vh;

                        @include breakpoint(sm) {
                            line-height: 1.8em;
                            margin: 0 0 9.3vw;
                            padding: 0vw 10vw;
                        }
                    }

                    .red-btn {
                        @include breakpoint(sm) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-21 {
            width: 150vh;
            background-color: $shark;
            padding: 8vh 9vh;
            margin-right: -1px;

            @include breakpoint(sm) {
                margin-top: 0;
                padding: 11vw 5vw;
                margin-right: 0px;
            }

            .emagazine-wrap {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .site-footer-left {
                width: 50%;
                padding-right: 2%;

                @include breakpoint(sm) {
                    width: 100%;
                }

                .site-footer-logo {
                    margin: 0 0 7vh;

                    @include breakpoint(sm) {
                        display: none;
                    }

                    img {
                        width: 15vh;

                        @include breakpoint(sm) {
                        }
                    }
                }

                .site-footer-text {
                    color: $white;

                    h2 {
                        color: $white;
                        margin: 0 0 7.5vh;

                        @include breakpoint(sm) {
                            font-size: 16vw;
                            line-height: 1em;
                            margin: 0 0 10.6vw;
                        }

                        span {
                            color: $red;
                        }
                    }

                    p {
                        font-size: 2.54vh;
                        margin: 0 0 4vh;

                        @include breakpoint(sm) {
                            margin: 0 0 8vw;
                            font-size: 5.54vw;
                        }

                        a {
                            color: inherit;
                            text-decoration: none;

                            &:hover {
                                color: $red;
                            }
                        }
                    }

                    .red-btn {
                        @include breakpoint(sm) {
                            width: 100%;
                        }
                    }
                }
            }

            .site-footer-left-bottom {
                width: 80%;
                margin: 12vh 0 0;
                font-size: 1.25vh;
                color: $white;
                font-family: $default-book;

                @include breakpoint(sm) {
                    width: 100%;
                    margin: 0;
                    font-size: 3.2vw;
                }

                p {
                    margin: 0 0 13px;
                    line-height: 1.2em;

                    @include breakpoint(sm) {
                        line-height: 2.1em;
                        margin: 0 0 6.5vw;
                    }
                }

                a {
                    color: inherit;

                    &:hover {
                        color: $red;
                    }
                }
            }

            .site-footer-right {
                width: 50%;
                color: $white;
                padding: 6.7vh 0 0;

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 9.5vw 0;
                }

                .site-footer-right-text {
                    margin: 0 0 7vh;

                    @include breakpoint(sm) {
                        margin: 0 0 6.7vw;
                    }

                    p {
                        @include breakpoint(sm) {
                            font-size: 4.53vw;
                            line-height: 1.64em;
                        }
                    }
                }

                a {
                    color: inherit;
                    text-decoration: underline;

                    &:hover {
                        color: $red;
                    }
                }

                .text-with-icon {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 4.5vh 0 0;

                    @include breakpoint(sm) {
                        margin: 0 0 5.6vw;
                        align-items: flex-start;
                    }

                    .icon {
                        width: 7.2vh;
                        display: inline-block;

                        @include breakpoint(sm) {
                            width: 6.67vw;
                            position: relative;
                            top: 2.5vw;
                        }
                    }

                    .text-block {
                        width: calc(100% - 7.2vh);
                        padding-left: 5.3vh;
                        font-size: 1.9vh;
                        position: relative;
                        top: -0.6vh;

                        @include breakpoint(sm) {
                            width: calc(100% - 6.67vw);
                            padding-left: 5.5vw;
                            top: 0;
                        }

                        h3 {
                            font-size: 2.55vh;
                            color: $white;
                            margin: 0 0 1vh;

                            @include breakpoint(sm) {
                                font-family: $default-book;
                                font-size: 5.9vw;
                                margin: 0 0 0.1vh;
                            }
                        }

                        p {
                            &:last-child {
                                margin: 0;
                            }

                            @include breakpoint(sm) {
                                font-size: 3.74vw;
                                line-height: 1.6em;
                            }
                        }
                    }
                }
            }
        }

        &-22 {
            .site-footer-left {
                width: 50%;

                @include breakpoint(sm) {
                    width: 100% !important;
                }

                p {
                    font-size: 2.5vh !important;
                    line-height: 1.45em;
                }

                .site-footer-left-bottom {
                    width: 100%;
                    margin: 7vh 0 0;
                }
            }

            .site-footer-right {
                width: 50%;

                @include breakpoint(sm) {
                    width: 100%;
                }

                .text-with-icon {
                    .icon {
                        width: 4vh;
                        position: relative;
                        top: -1.3vh;

                        @include breakpoint(sm) {
                            width: 5vw;
                            top: 2.5vw;
                        }
                    }

                    .text-block {
                        width: calc(100% - 4vh);
                        padding-left: 2vh;

                        @include breakpoint(sm) {
                            width: calc(100% - 5vw);
                            padding-left: 4vw;
                        }
                    }
                }
            }

            h2 {
                font-size: 12.6vh;
                line-height: 0.91em;
            }
        }


        &-23 {
            width: 150vh;
            padding: 10.5vh 10.5vh 6vh;
            position: relative;

            @include breakpoint(sm) {
                margin: 15.5vw 0 0 !important;
                display: block;
                padding: 0;
            }

            &__left {
                width: 75%;
                height: calc(100vh - 10.5vh);
                margin: 0 2vh 0 0;
                position: relative;

                @include breakpoint(sm) {
                    padding: 17.3vw 5vw 15.3vw !important;
                    width: 100%;
                    height: auto;
                    margin: 0;
                }

                .topleft {
                    width: 60%;
                    float: left;

                    @include breakpoint(sm) {
                        width: 100%;
                        float: none;
                        padding: 0;
                    }
                }

                .topright {
                    width: 40%;
                    height: 55vh;
                    float: right;
                    padding-bottom: 2vh;

                    @include breakpoint(sm) {
                        width: 100%;
                        float: none;
                        padding: 2vh 0;
                    }

                    .advice_image {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            &__right {
                width: 25%;
                background: $light-gray;
                padding: 5vh;

                h3 {
                    font-size: 2.55vh;
                }

                p {
                    font-size: 1.5vh;
                }

                sup {
                    line-height: 1;
                }

                @include breakpoint(sm) {
                    width: 100%;
                    padding: 2vh;
                    margin: 0;
                }
            }

            &__heading {
                margin-right: 4vh;

                h2 {
                    font-size: 12.5vh;
                    line-height: 0.92em;
                }

                p {
                    color: $red;
                    font-size: 3.13vh;
                    font-family: "toyota_typebook";
                    line-height: 1.2em;
                    margin: 0;
                }
            }

            &__bottom {
                width: 100%;
                height: 28vh;
                padding: 5vh;
                background: $shark;
                bottom: 6vh;
                position: absolute;

                p {
                    font-size: 1.77vh;
                    line-height: 1.45em;
                    color: $white;
                }

                &__left {
                    width: 60%;
                    float: left;
                    padding-right: 6vh;
                }

                &__right {
                    width: 40%;
                    float: right;
                    padding-left: 1vh;

                    h4 {
                        font-size: 2.55vh;
                        color: $white;
                        margin: 0 0 1.5vh;
                        text-transform: uppercase;
                    }

                    .col-50 {
                        width: 50%;
                        float: left;

                        input, button {
                            border-radius: 0;
                            border: none;
                            padding: 1vh 2vh;
                            font: 400 1.5vh/1 "Toyota Type", sans-serif;
                            margin: .5vh 0;
                            width: 100%;
                            color: $blackPearl;
                            height: 4vh;
                            padding-left: 5vh;
                        }

                        button {
                            padding-left: 0;
                        }

                        label {
                            position: relative;
                            display: block;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0.5vh;
                                left: 0;
                                width: 4vh;
                                height: 4vh;
                                line-height: 4vh;
                                vertical-align: middle;
                                font-size: 1.5vh;
                                font-weight: bold;
                                text-align: center;
                            }

                            &.kenteken {
                                &:after {
                                    content: 'NL';
                                    background: #4A90E2;
                                    color: $white;
                                }

                                input {
                                    background: #FFC002;
                                }
                            }

                            &.kilometer {
                                &:after {
                                    content: 'KM';
                                    background: #AEAEAF;
                                    color: $black;
                                }

                                input {
                                    background: #CECFD0;
                                }
                            }
                        }
                    }
                }

                @include breakpoint(sm) {
                    position: relative;
                    width: 100%;
                    height: auto;
                    padding: 2vh;
                    bottom: 0;

                    &__left {
                        width: 100%;
                        float: none;
                    }

                    &__right {
                        width: 100%;
                        float: none;
                    }
                }
            }
        }

        &-25 {
            width: 150vh;
            position: relative;

            .table {
                border: 0;

                td {
                    border: 0 !important;
                    padding-left: 0 !important;
                    padding-bottom: 0 !important;
                }
            }

            &__left {
                width: 40%;
                float: left;
                position: relative;

                .gray_box {
                    background-color: $shark;
                    position: absolute;
                    top: 10.5vh;
                    width: 64vh;
                    left: 25vh;
                    padding: 6vh 6.8vh;
                    z-index: 1;
                    color: $white;
                    min-width: fit-content;

                    h2 {
                        margin-bottom: .5em;
                    }
                }

                .overview_image {
                    width: 27vh;
                    //height: 27vh;
                    overflow: hidden;
                    position: absolute;
                    bottom: 10.5vh;
                    right: 5vh;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                @include breakpoint(sm) {
                    width: 100%;
                    float: none;
                    padding: 0;
                    position: static;

                    .gray_box {
                        position: static;
                        width: 100%;
                        padding: 5vw;
                    }

                    .overview_image {
                        position: static;
                        width: 100%;
                        height: 67vw;
                        bottom: 0;
                        right: 0;
                    }
                }
            }

            &__right {
                width: 60%;
                float: right;
                padding: 10.5vh 8vh 10.5vh 25vh;

                @include breakpoint(sm) {
                    width: 100%;
                    float: none;
                    padding: 5vw;
                }
            }
        }

        &-26 {
            width: 160vh;
            position: relative;
            padding: 10.5vh 8vh 10.5vh 8vh;

            @include breakpoint(sm) {
                padding: 0;
            }


            .emagazine-wrap {
                position: relative;

                @include breakpoint(sm) {
                    padding: 17.3vw 5vw 5vw;
                }
            }

            &__left {
                width: 40%;
                float: left;

                @include breakpoint(sm) {
                    width: 100%;
                    float: none;
                    padding: 0;
                }
            }

            &__right {
                width: 60%;
                height: 40vh;
                float: right;
                background-color: $shark;
                padding: 6vh 6.8vh 6vh 21vh;
                color: $white;
                position: relative;

                h3 {
                    width: 30vw;
                    bottom: 4vh;
                    position: absolute;
                }

                .distance {
                    float: right;
                    width: 40%;
                    position: absolute;
                    bottom: 9vh;
                    right: 0;
                }

                @include breakpoint(sm) {
                    width: 100%;
                    float: none;
                    padding: 5vw;
                    height: auto;

                    h3 {
                        width: 100%;
                        bottom: 0;
                        position: static;
                    }

                    .distance {
                        width: 100%;
                        position: static;
                        bottom: 0;
                        right: 0;
                        margin-top: 1vh;
                    }
                }
            }

            .address {
                position: absolute;
                left: 79vh;
                top: 45.6vh;
                width: 40%;

                @include breakpoint(sm) {
                    position: static;
                    width: 100%;
                    padding: 2vh 0 0;
                }
            }


            .buttons {
                bottom: 3.6vh;
                position: absolute;
                left: 79vh;

                a {
                    margin: 0 5vh 0 0;
                }

                @include breakpoint(sm) {
                    position: static;
                    width: 100%;
                    padding: 2vh 0 0;

                    a {
                        width: 100%;
                        margin: 1vh 0;
                    }
                }
            }

            .navigate-btn {
                position: absolute;
                bottom: 3.6vh;
                left: 3vh;

                @include breakpoint(sm) {
                    position: static;
                    width: 100%;
                    margin: 0;
                    text-align: center;
                    display: none;
                }
            }

            .map {
                width: 50%;
                height: 65vh;
                position: absolute;
                bottom: 0;
                left: 0;

                iframe {
                    width: 100%;
                    height: 100%;
                }

                @include breakpoint(sm) {
                    margin: 2vh 0 0;
                    width: 100%;
                    height: 50vh;
                    position: relative;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

    .play-icon {
        width: 0;
        height: 0;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        border-left: 45px solid $white;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint(sm) {
            border-top: 5vw solid transparent;
            border-bottom: 5vw solid transparent;
            border-left: 8vw solid #ffffff;
        }
    }

    .video-player {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .e-magazine-section-5 {
        .video-player {
            position: relative;
            top: 50%;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .bg-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .red-text {
        color: $red;
    }

    .for-desktop {
        display: block;

        @include breakpoint(sm) {
            display: none;
        }
    }

    .for-mobile {
        display: none;

        @include breakpoint(sm) {
            display: block;
        }
    }

    .show-scroll-section {
        .e-magazine-vertical-section {
            min-height: 100vh;
            height: auto;
            z-index: 1;

            @include breakpoint(sm) {
                margin-top: 19.2vw;
            }
        }
    }
